document.getElementById('openMenu').addEventListener('click', showMenu);
document.getElementById('closeMenu').addEventListener('click', hideMenu);

function showMenu() {
  let menu = document.getElementById('menu-mobile');
  menu.classList.add('visible');
}

function hideMenu() {
  let menu = document.getElementById('menu-mobile');
  menu.classList.remove('visible');
}
